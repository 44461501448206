import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useI18next } from "gatsby-plugin-react-i18next"

const NotFoundPage = () => {
  const { t } = useI18next()
  return (
    <Layout>
      <Seo
        title={t("play-store.seo.title")}
        description={t("play-store.seo.description")}
      />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
