import React from "react"
import { Column, Row, Container } from "../styled"
import AppleStore from "../../images/button-app-store.svg"
import PlayStore from "../../images/button-play-store.svg"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import ExtendedLandingStepLogo from "../../images/YP_Step_Esteso.svg"
import ContractLandingStepLogo from "../../images/YP_Step_Contratto.svg"
import { useBreakpointValue } from "@youngagency/young-ui"
import { createGAEvent } from "../../utils"

const CustomLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`

const StoreImg = styled.img`
  max-width: 120px;
  height: 36px;
  width: 100%;
  object-fit: fill;
`

const LogoImg = styled.img`
  max-width: 209px;
  width: 100%;
  height: 36px;
`

const Header = () => {
  const logoImg = useBreakpointValue([
    ContractLandingStepLogo,
    ExtendedLandingStepLogo,
    ExtendedLandingStepLogo,
  ])

  return (
    <Container
      size="fullwidth"
      height={["56px", "72px"]}
      position="fixed"
      display="flex"
      top="0px"
      left="0px"
      right="0px"
      zIndex="999"
      background="white"
    >
      <Container position="relative" height="100%">
        <Column
          gap="none"
          justify="center"
          align="start"
          width="100%"
          height="100%"
        >
          <Row
            gap="none"
            align="center"
            justify={["start", "space-between"]}
            width="100%"
          >
            <Link to="/">
              <LogoImg src={logoImg} alt="Young Platform Step Logo" />
            </Link>
            <Row
              gap="xxs"
              align="baseline"
              justify="center"
              display={["none", "flex"]}
            >
              <CustomLink
                title="go to play store"
                onClick={() => {
                  createGAEvent("click_cta", "download_step")()

                  navigate("https://youngplatform.page.link/XLkB", {
                    replace: true,
                  })
                }}
              >
                <StoreImg src={PlayStore} />
              </CustomLink>
              <CustomLink
                title="go to apple store"
                onClick={() => {
                  createGAEvent("click_cta", "download_step")()
                  navigate("https://youngplatform.page.link/cuVF", {
                    replace: true,
                  })
                }}
              >
                <StoreImg src={AppleStore} />
              </CustomLink>
            </Row>
          </Row>
        </Column>
      </Container>
    </Container>
  )
}

export default Header
