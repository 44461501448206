import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import "./landing.css"
import Header from "./header/header.component"
import { Spacer } from "./styled"
import { I18nextContext } from "gatsby-plugin-react-i18next"
import Footer from "../components/footer/footer"
import { CookieConsent } from "@youngagency/young-ui"
const Layout = ({ children, disableFooter }) => {
  const context = React.useContext(I18nextContext)
  const lng = context.language

  return (
    <>
      <Header />
      <Spacer height={["56px", "72px"]} />
      <main
        style={{
          position: "realtive",
        }}
      >
        {children}
      </main>
      <CookieConsent
        lang={lng}
        apiKey={process.env.COOKIE_API_KEY}
        siteId={Number(process.env.COOKIE_SITE_ID)}
        cookiePolicyId={Number(process.env.COOKIE_POLICY_ID)}
      />
        {!disableFooter && <Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
